document.addEventListener("DOMContentLoaded", app);

function app() {
  allSliders();
}

function allSliders() {

  const sliderElement = document.querySelector('.presents_swiper');
  if (sliderElement) {
    const swiper = new Swiper('.presents_swiper', {
        loop: true,
        slidesPerView: 1,
      
        pagination: {
          el: '.presents_pagination',
          clickable: true,
        },
        autoplay: {
            delay: 3000,
        },
      
        navigation: {
          nextEl: '.presents_btn_next',
          prevEl: '.presents_btn_prev',
        },
    });
  }
  

}